/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
/* import cookie from "react-cookies"; */
import {} from "recharts";
import { UopUrl, apiUrl } from "../Helpers/Config";

import backarrow from "../../common/images/back-arrow-front.svg";

import axios from "axios";
import cookie from "react-cookies";

import { GET_CUSTOMER_DETAIL } from "../../actions";
import SubHeader from "./SubHeader";
import ModalPopup from "./ModalPopup/ModalPopup";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log("listPage", this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    var postedDay = [];
    let allowedSection = [];
    let packageID = "";
    let newPackage = true;
    let duration;
    let postedSection = [];
    if (this.props?.location?.state?.postedDay !== undefined) {
      postedDay = this.props?.location?.state?.postedDay;
    }
    if (this.props?.location?.state !== undefined) {
      allowedSection = this.props?.location?.state?.allowedSection;
      packageID = this.props?.location?.state?.packageID;
    }
    if (!allowedSection?.includes("itinerary-list")) {
      this.props.history.push("/myaccount/general-info");
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    }
    if (this.props?.location?.state?.duration !== undefined) {
      duration = this.props?.location?.state?.duration;
    }
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      creator: [],
      duration: duration,
      postedDay: postedDay,
      allowedSection: allowedSection,
      packageID: packageID,
      openModal: false,
      newPackage: newPackage,
      postedSection: postedSection,
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    } */
  }
  componentDidMount() {
    const { newPackage, duration } = this.state;
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    let posted = this.state.postedSection.includes("itinerary-list");
    if (!newPackage || posted) {
      this.getItineraryData();
    }
    this.durationCount(duration);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }

  getItineraryData = async () => {
    try {
      const { packageID, allowedSection, duration } = this.state;
      const res = await axios(
        `${UopUrl}/itinerary?id=${packageID}`,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );

      // console.log(res);
      if (res.data?.length > 0) {
        let updatedSection = [...allowedSection];
        let postedDay = [];

        if (parseInt(duration) === res.data.length) {
          this.durationCount(res.data.length);
        }
        if (!allowedSection.includes("availability")) {
          updatedSection.push("availability");
        }
        res.data.map((item) => {
          postedDay.push(parseInt(item.day));
        });
        this.setState({
          allowedSection: updatedSection,
          postedDay: [...new Set(postedDay)],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }

  durationCount = (count) => {
    try {
      const { postedDay, allowedSection } = this.state;
      var numArray = [];
      var i = 1;
      while (i <= count) {
        numArray.push(i);
        this.setState({ duration: numArray });
        i++;
      }
      if (
        postedDay.includes(this.state.duration.length - 1) &&
        !allowedSection.includes("availability")
      ) {
        allowedSection.push("availability");
      }
    } catch (err) {
      console.log(err);
    }
  };

  getFormattedDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}`;
  };

  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value, [name + "_error"]: "" });
  }

  addInfo = (day) => {
    try {
      var propsDetails = this.props.location.state;
      propsDetails["day"] = day;
      // propsDetails["duration"] = this.state.duration;
      propsDetails["isNewPackageDay"] = !this.state.postedDay.includes(day);
      this.props.history.push({
        pathname: "/myaccount/itinerary-activity",
        state: propsDetails,
      });
    } catch (err) {
      console.log(err);
    }
  };

  availabilityPage = () => {
    let isAllFieldFilled = this.isAllFieldFilled();
    if (isAllFieldFilled) {
      console.log(this.state.allowedSection, "this.state.allowedSection");
      let allowedSection = [...this.state.allowedSection];
      if (!allowedSection.includes("availability")) {
        allowedSection.push("availability");
      }
      let packageDetails = this.props.location.state;
      packageDetails["allowedSection"] = allowedSection;
      this.props.history.push({
        pathname: "/myaccount/availability",
        state: packageDetails,
      });
    } else {
      this.setState({ openModal: true });
    }
  };

  handlePopup = (value) => {
    this.setState({ openModal: value });
  };

  isAllFieldFilled = () => {
    const { duration, postedDay } = this.state;
    console.log(duration, postedDay);
    if (duration.length !== postedDay.length) {
      return false;
    }
    for (let i = 0; i > duration.length; i++) {
      if (duration[i] !== postedDay[i]) {
        return false;
      }
    }
    return true;
  };

  render() {
    const { postedDay, allowedSection, duration } = this.state;
    return (
      <>
        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <section className="main-blue-bg">
                <div className="container-full">
                  <SubHeader
                    triggerAction={this.availabilityPage}
                    allowedSection={this.props?.location?.state?.allowedSection}
                    stateValues={this.props?.location?.state}
                  />
                  <div className="spread">
                    <ul>
                      {this.state.duration?.length ? (
                        this.state.duration.map((item, index) => {
                          return (
                            <li className="spread-box">
                              <div className="spread-box-content">
                                Day {index + 1} information
                              </div>
                              <div className="spread-box-button">
                                <a
                                  className="button"
                                  onClick={() => this.addInfo(index + 1)}
                                >
                                  {postedDay.includes(item)
                                    ? "Edit information"
                                    : "Add information"}
                                </a>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <li className="spread-box">
                          <div className="spread-box-content">
                            Day 1 information
                          </div>
                          <div className="spread-box-button">
                            <a
                              className="button"
                              onClick={() => this.addInfo(1)}
                            >
                              Add information
                            </a>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </section>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <ModalPopup
          type="warning"
          title="Warning"
          message="Please provide all the day information to continue"
          open={this.state.openModal}
          handleClose={() => this.handlePopup(false)}
          // handleChange={handleChange}
          // handleSubmit={handleAuth}
        />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
