/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  GET_PRODUCTLIST,
  GET_SEGMENTLIST,
  GET_TAGLIST,
  GET_FAVOURITE,
} from "../../actions";
import {
  showAlert,
  showLoader,
  hideLoader,
  stripslashes,
} from "../Helpers/SettingHelper";
import { apiUrl, apiglobaltixMediaUrl } from "../Helpers/Config";
import {
  pplaceholder,
  barrow,
  sort,
  filter,
  heart,
  heartfill,
  searchlight,
  stario,
  ticketblue,
  dollarblue,
  stickclose,
} from "../Helpers/Images";
var qs = require("qs");
class Index extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    const queryParams = new URLSearchParams(window.location.search);
    const activitiesurl = queryParams.get("activities");
    var activities_url = "";
    if (
      activitiesurl !== "" &&
      activitiesurl !== null &&
      typeof activitiesurl !== undefined &&
      typeof activitiesurl !== "undefined"
    ) {
      var activitiessplit = activitiesurl.split("~");
      activities_url = activitiessplit[1];
    }
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    }
    var country = "";
    if (
      this.props.match.params.country !== "" &&
      this.props.match.params.country !== undefined &&
      this.props.match.params.country !== "undefined"
    ) {
      country = this.props.match.params.country;
    }
    var package_city = "";
    if (
      this.props.match.params.city !== "" &&
      this.props.match.params.city !== undefined &&
      this.props.match.params.city !== "undefined"
    ) {
      package_city = this.props.match.params.city;
    }

    var packageID = "";
    if (
      this.props.match.params.packageID !== "" &&
      this.props.match.params.packageID !== undefined &&
      this.props.match.params.packageID !== "undefined"
    ) {
      packageID = this.props.match.params.packageID;
    }
    var dayno = "";
    if (
      this.props.match.params.day !== "" &&
      this.props.match.params.day !== undefined &&
      this.props.match.params.day !== "undefined"
    ) {
      dayno = this.props.match.params.day;
    }
    var dayType = "";
    if (
      this.props.match.params.dayType !== "" &&
      this.props.match.params.dayType !== undefined &&
      this.props.match.params.dayType !== "undefined"
    ) {
      dayType = this.props.match.params.dayType;
    }

    var existActivity = "";

    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existActivity =
        this.props.location.state.existActivity !== "" &&
        typeof this.props.location.state.existActivity !== undefined &&
        typeof this.props.location.state.existActivity !== "undefined"
          ? this.props.location.state.existActivity
          : [];
    }

    var newPackage = "";
    if (
      this.props.location.state.newPackage !== "" &&
      this.props.location.state.newPackage !== undefined &&
      this.props.location.state.newPackage !== "undefined"
    ) {
      newPackage = this.props.location.state.newPackage;
    }
    this.state = {
      loading: true,
      CUserId: CUserId,
      country: country,
      package_city: package_city,
      packageID: packageID,
      dayno: dayno,
      dayType: dayType,
      productlist: [],
      displayProducts: "",
      page: 1,
      pagenation: "",
      disablelast: false,
      segmentlist: [],
      displaysegment: "",
      taglist: [],
      displaytag: "",
      filterTag: [],
      filterSegment: [activities_url],
      selectedrating: "",
      addfavloading: "",
      startPrice: "",
      endPrice: "",
      enablesortby: false,
      pricesort: "",
      showFilter: false,
      productListStatus: "",
      selectedProduct: [],
      selectedProductID: [],
      selectedpackage: [],
      search_keyword: "",
      existProductList: [],
      initialPackageProduct: false,
      selectedActivities: [],
      showsearchList: false,
      displaySearchCountry: "",
      displaySearchProducts: "",
      selectActivity: [],
      existActivity: existActivity,

      newPackage: newPackage,
    };
    this.dropdownRef = React.createRef();
    this.props.getSegmentList();
    this.props.getTagList();
  }
  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    this.selectCreatorProduct();
    var currentThis = this;
    $(function () {
      $("#rang").ionRangeSlider({
        min: 0,
        max: 10000,
        from: 1000,
        to: 9000,
        type: "double",
        grid_num: 10,
        onFinish: function (data) {
          currentThis.setState(
            { startPrice: data.from, endPrice: data.to },
            function () {
              currentThis.applyFilter();
            }
          );
        },
      });
      $("#rangmobile").ionRangeSlider({
        min: 0,
        max: 10000,
        from: 1000,
        to: 9000,
        type: "double",
        grid_num: 10,
        onFinish: function (data) {
          currentThis.setState(
            { startPrice: data.from, endPrice: data.to },
            function () {
              currentThis.applyFilter();
            }
          );
        },
      });
    });
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.productListStatus !== this.state.productListStatus) {
      this.setState(
        { productListStatus: PropsData.productListStatus },
        function () {
          if (PropsData.productListStatus === "success") {
            this.setState({ loading: false });
          }
        }
      );
    }
    if (PropsData.productlist !== this.state.productlist) {
      this.setState(
        { productlist: PropsData.productlist, loading: false },
        function () {
          this.displayProductList();
        }
      );
    }
    if (PropsData.segmentlist !== this.state.segmentlist) {
      this.setState({ segmentlist: PropsData.segmentlist }, function () {
        this.displaySegment();
      });
    }
    if (PropsData.taglist !== this.state.taglist) {
      this.setState({ taglist: PropsData.taglist }, function () {
        this.displayTag();
      });
    }
    if (this.state.addfavloading === "loading") {
      if (PropsData.favourite !== undefined) {
        this.showMessage(PropsData.favourite[0]);
      }
    }
  }
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    if (value !== "") {
      axios
        .get(
          apiUrl +
            "products/search?keywords=" +
            value +
            "&country=" +
            this.state.country,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            if (
              res.data.result_set.country.length > 0 ||
              res.data.result_set.product_list.length > 0
            ) {
              this.displaySearch(res.data.result_set);
            } else {
              this.setState({
                displaySearchCountry: "",
                displaySearchProducts: "",
                showsearchList: false,
              });
            }
          }
        });
    } else {
      this.setState({
        displaySearchCountry: "",
        displaySearchProducts: "",
        showsearchList: false,
      });
    }
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  displaySearch(result) {
    var displaySearchCountry = "";
    var displaySearchProducts = "";
    if (result.country.length > 0) {
      displaySearchCountry = result.country.map((item, index) => {
        var countryName = item.replace(" ", "-").toLowerCase();
        return (
          <li key={index}>
            <Link to={"/activities/" + countryName}>
              <figure>
                {" "}
                <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
              </figure>
              <div className="search-show">
                <p>{item}</p>
              </div>
            </Link>
          </li>
        );
      });
    }
    if (result.product_list.length > 0) {
      displaySearchProducts = result.product_list.map((item, index) => {
        return (
          <li
            key={index}
            onClick={this.selectProduct.bind(this, item)}
            id={"pro_" + item.products_id}
            className={
              item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0
                ? "not-available"
                : ""
            }
          >
            <figure>
              {" "}
              <img
                src={
                  item.product_thumbnail !== "" &&
                  item.product_thumbnail !== null
                    ? apiglobaltixMediaUrl + item.product_thumbnail
                    : pplaceholder
                }
                alt={item.products_name}
              />
            </figure>
            <div className="search-show active">
              <div className="search-show-inner">
                <div className="search-show-product">
                  <p>{item.products_name}</p>
                  <span>{item.country_name}</span>
                </div>
                <div className="search-price">
                  <em>From</em>
                  <strong>SGD ${item.product_starting_price}</strong>
                </div>
              </div>{" "}
            </div>
          </li>
        );
      });
    }
    this.setState({
      displaySearchCountry: displaySearchCountry,
      displaySearchProducts: displaySearchProducts,
      showsearchList: true,
    });
  }

  loaddayPackageProduct() {
    this;
    axios
      .get(
        apiUrl +
          "package/packageActivityList?package_id=" +
          this.state.packageID +
          "&creator_id=" +
          this.state.CUserId +
          "&dayno=" +
          this.state.dayno,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((res) => {
        var existProductID = [];
        var existPackageID = [];
        var selectedActivities = [];
        var selectActivity = [];

        if (res.data.status === "ok") {
          existProductID = res.data.result_set.product_id;
          existPackageID = res.data.result_set.package_id;
          selectedActivities = res.data.result_set.activities;
        }
        this.setState(
          {
            selectedProductID: existProductID,
            selectedpackage: existPackageID,
            selectedActivities: selectedActivities,
          },
          function () {
            this.displayProductList();
          }
        );
      });
  }

  selectCreatorProduct() {
    var selectedPro = this.props.location;
    var selectedProductID = [];
    var selectedpackage = [];
    var selectedActivities = [];
    if (
      selectedPro.state !== "" &&
      typeof selectedPro.state !== undefined &&
      typeof selectedPro.state !== "undefined"
    ) {
      selectedProductID =
        selectedPro.state.selectedProduct !== "" &&
        typeof selectedPro.state.selectedProduct !== undefined &&
        typeof selectedPro.state.selectedProduct !== "undefined"
          ? selectedPro.state.selectedProduct
          : [];
      selectedpackage =
        selectedPro.state.selectedPackage !== "" &&
        typeof selectedPro.state.selectedPackage !== undefined &&
        typeof selectedPro.state.selectedPackage !== "undefined"
          ? selectedPro.state.selectedPackage
          : [];
      selectedActivities =
        selectedPro.state.selectedActivities !== "" &&
        typeof selectedPro.state.selectedActivities !== undefined &&
        typeof selectedPro.state.selectedActivities !== "undefined"
          ? selectedPro.state.selectedActivities
          : [];
      this.setState(
        {
          selectedProductID: selectedProductID,
          selectedpackage: selectedpackage,
          selectedActivities: selectedActivities,
        },
        function () {
          this.displayProductList();
        }
      );
    } else {
      this.setState({ initialPackageProduct: true });
    }
  }

  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var result = response.result_set;
      if (result.addremovetype === "A") {
        $("#pro_" + result.product_id + " .like").addClass("active");
      } else {
        $("#pro_" + result.product_id + " .like").removeClass("active");
      }
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  displayProductList() {
    var productList = "";
    var pagenation = "";
    var disablelast = false;
    var totalproductdisplay = 0;
    if (this.state.productlist.length > 0) {
      productList = this.state.productlist.map((item, index) => {
        totalproductdisplay++;
        // var typeofselect =
        //   this.state.selectedProductID.indexOf(item.products_id) >= 0
        //     ? "remove"
        //     : "add";
        return (
          <li
            onClick={this.selectProduct.bind(this, item)}
            key={index}
            id={"pro_" + item.products_id}
            className={
              item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0
                ? "not-available"
                : ""
            }
          >
            {item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0 && (
                <span className="no-package">Not Available</span>
              )}
            <div className="product-img">
              {/* <a
                href={void 0}
                className={item.favourite === "Y" ? "like active" : "like"}
                onClick={this.addRemoveFavourite.bind(this, item.products_id)}
              >
                {" "}
                <img src={heart} alt="" /> <img src={heartfill} alt="" />
              </a> */}
              <li className="pt-img">
                <img
                  src={
                    item.product_thumbnail !== "" &&
                    item.product_thumbnail !== null
                      ? apiglobaltixMediaUrl + item.product_thumbnail
                      : pplaceholder
                  }
                  alt={item.products_name}
                />
              </li>
            </div>
            <div className="product-description">
              <div className="product-description-title">
                <h5>
                  {/* <Link to={"/product-details/" + item.product_slug}> */}
                  {item.products_name}
                  {/* </Link>{" "} */}
                </h5>
              </div>

              {item.product_category_name !== "" &&
                item.product_category_name !== null && (
                  <div className="product-description-tag">
                    <span>{item.product_category_name}</span>
                  </div>
                )}
              <div className="product-description-review">
                <div className="pdr-lhs">
                  {item.product_rating !== "" &&
                    item.product_rating !== null && (
                      <span>
                        <strong>{item.product_rating}</strong> (
                        {item.product_total_review}) Reviews
                      </span>
                    )}
                  <div className="star-rating">
                    {this.loadRatingstar(item.product_rating)}
                  </div>
                </div>
                <div className="pdr-rhs">
                  <span>From</span>
                  <strong>SGD ${item.product_payable_amount}</strong>
                </div>
              </div>
              {/* {item.product_total_package === null ||
                (parseInt(item.product_total_package) !== 0 && (
                  <a
                    href={void 0}
                    onClick={this.selectProduct.bind(this, item, typeofselect)}
                    className="button"
                  >
                    {this.state.selectedProductID.indexOf(item.products_id) >=
                      0 && "Remove"}
                  </a>
                ))} */}
            </div>
          </li>
        );
      });
      var start = 1;
      if (this.state.page - 2 > 0) {
        start = this.state.page - 2;
      }
      var max = Math.ceil(this.props.totalRecord / 12);
      if (max > 1) {
        var end = "";
        if (max === parseInt(this.state.page) + 1) {
          disablelast = true;
        }

        if (parseInt(start) + 3 > max) {
          end = max;
        } else {
          end = parseInt(start) + 3;
        }

        var pageList = [];
        for (let index = start; index <= end; index++) {
          pageList.push(index);
        }
        pagenation = pageList.map((item, index) => {
          return (
            <li
              key={index}
              className={this.state.page === item ? "active" : ""}
            >
              <a href={void 0} onClick={this.loadMoreProduct.bind(this, item)}>
                {item}
              </a>
            </li>
          );
        });
      }
    }
    var initialPackageProduct = this.state.initialPackageProduct;
    this.setState(
      {
        displayProducts: productList,
        pagenation: pagenation,
        disablelast: disablelast,
        initialPackageProduct: false,
      },
      function () {
        if (initialPackageProduct === true) {
          this.loaddayPackageProduct();
        }
        /*  this.setState({ loading: false }, function () { */
        setTimeout(function () {
          if (totalproductdisplay > 0) {
            $("#no-product").hide();
          } else {
            $("#no-product").show();
          }
        }, 2000);
        /*  }); */
      }
    );
  }
  addRemoveFavourite(products_id) {
    if (this.state.UserId !== "") {
      var postObject = {
        favourite_customer_id: this.state.UserId,
        favourite_product_id: products_id,
      };
      this.setState({ addfavloading: "loading" });
      this.props.getFavouritelist(qs.stringify(postObject));
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }
  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return <i className="fa fa-star-half-o" aria-hidden="true"></i>;
          } else {
            return <i className="fa fa-star" aria-hidden="true"></i>;
          }
        }
      });
    }
  }
  loadMoreProduct(page) {
    if (this.state.page !== page) {
      this.setState({ page: page }, function () {
        var tag_filter = "";
        var segment_filter = "";
        if (this.state.filterTag.length > 0) {
          tag_filter = this.state.filterTag.join(",").replace("&", "!!");
        }
        if (this.state.filterSegment.length > 0) {
          segment_filter = this.state.filterSegment
            .join(",")
            .replace("&", "!!");
        }
        var Fcondition = "";
        if (this.state.selectedrating !== "") {
          Fcondition += "&rating=" + this.state.selectedrating;
        }
        if (this.state.startPrice !== "" && this.state.endPrice !== "") {
          Fcondition +=
            "&startPrice=" +
            this.state.startPrice +
            "&endPrice=" +
            this.state.endPrice;
        }
        if (this.state.pricesort !== "") {
          Fcondition += "&sort_price=" + this.state.pricesort;
        }
        var params =
          "page=" +
          page +
          "&country=" +
          this.state.country +
          "&city=" +
          this.state.package_city;
        params +=
          "&tag_filter=" +
          tag_filter +
          "&segment_filter=" +
          segment_filter +
          Fcondition;

        this.props.getProductList(params);
      });
    }
  }
  displaySegment() {
    var displaysegment = "";
    if (this.state.segmentlist.length > 0) {
      displaysegment = this.state.segmentlist.map((item, index) => {
        return (
          <li
            key={index}
            className={
              this.state.filterSegment.indexOf(item.segment_name) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.filterBySegment.bind(this, item.segment_name)}
            >
              {item.segment_name}
            </a>
          </li>
        );
      });
    }
    this.setState({ displaysegment: displaysegment });
  }
  displayTag() {
    var displaytag = "";
    if (this.state.taglist.length > 0) {
      displaytag = this.state.taglist.map((item, index) => {
        return (
          <li
            key={index}
            className={this.state.filterTag.indexOf(item) >= 0 ? "active" : ""}
          >
            <a href={void 0} onClick={this.filterByCategory.bind(this, item)}>
              {item}
            </a>
          </li>
        );
      });
    }
    this.setState({ displaytag: displaytag });
  }
  filterByCategory(tagID) {
    var filterTag = this.state.filterTag;
    if (filterTag.indexOf(tagID) < 0) {
      filterTag.push(tagID);
    } else {
      const index = filterTag.indexOf(tagID);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.applyFilter();
    });
  }
  filterBySegment(segmentID) {
    var filterSegment = this.state.filterSegment;
    if (filterSegment.indexOf(segmentID) < 0) {
      filterSegment.push(segmentID);
    } else {
      const index = filterSegment.indexOf(segmentID);
      filterSegment.splice(index, 1);
    }
    this.setState({ filterSegment: filterSegment }, function () {
      this.applyFilter();
    });
  }
  filterByRating(rating) {
    var selectedrating = "";
    if (
      this.state.selectedrating !== "" &&
      this.state.selectedrating === rating
    ) {
      selectedrating = "";
    } else {
      selectedrating = rating;
    }
    this.setState({ selectedrating: selectedrating }, function () {
      this.applyFilter();
    });
  }
  applyFilterMobile() {
    this.setState({ showFilter: false }, function () {
      this.applyFilter();
    });
  }
  applyFilter() {
    var tag_filter = "";
    var segment_filter = "";
    if (this.state.filterTag.length > 0) {
      tag_filter = this.state.filterTag.join(",").replace("&", "!!");
    }
    if (this.state.filterSegment.length > 0) {
      segment_filter = this.state.filterSegment.join(",").replace("&", "!!");
    }
    var Fcondition = "";
    if (this.state.selectedrating !== "") {
      Fcondition += "&rating=" + this.state.selectedrating;
    }
    if (this.state.startPrice !== "" && this.state.endPrice !== "") {
      Fcondition +=
        "&startPrice=" +
        this.state.startPrice +
        "&endPrice=" +
        this.state.endPrice;
    }
    if (this.state.pricesort !== "") {
      Fcondition += "&sort_price=" + this.state.pricesort;
    }

    if (this.state.search_keyword !== "") {
      Fcondition += "&search_keyword=" + this.state.search_keyword;
    }

    var params =
      "page=1&country=" +
      this.state.country +
      "&city=" +
      this.state.package_city +
      "&tag_filter=" +
      tag_filter +
      "&segment_filter=" +
      segment_filter +
      Fcondition;

    this.setState({ loading: true });

    $("html, body").animate(
      {
        scrollTop: $(".outlet-listing-rhs").offset().top - 200,
      },
      500
    );
    this.displayTag();
    this.displaySegment();
    this.props.getProductList(params);
  }
  resetFilter(filterType) {
    if (filterType === "tag") {
      this.setState({ filterTag: [] }, function () {
        this.applyFilter();
      });
    } else if (filterType === "rating") {
      this.setState({ selectedrating: "" }, function () {
        this.applyFilter();
      });
    }
    this.setState({ showFilter: false });
  }
  clearAllFilters() {
    this.setState(
      {
        filterTag: [],
        startPrice: 0,
        endPrice: 0,
        selectedrating: "",
      },
      () => {
        this.applyFilter();
      }
    );
    this.setState({ showFilter: false });
  }
  openSortBy() {
    this.setState({ enablesortby: !this.state.enablesortby });
  }
  applyPriceSort(type) {
    this.setState({ pricesort: type }, function () {
      this.applyFilter();
    });
  }
  applyPriceSortMobile(pricesort) {
    var type = "LH";
    if (pricesort === "LH") {
      type = "HL";
    }
    if (pricesort === "HL") {
      type = "LH";
    }
    this.setState({ pricesort: type }, function () {
      this.applyFilter();
    });
  }
  showFilter() {
    this.setState({ showFilter: true }, function () {
      $("#freshworks-container").hide();
    });
  }

  closeFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  searchProduct() {
    this.applyFilter();
    this.setState({
      displaySearchCountry: "",
      displaySearchProducts: "",
      showsearchList: false,
    });
  }

  selectProduct(item) {
    var checkingselectedProductID = this.state.selectedProductID;
    var checkingselectedpackage = this.state.selectedActivities;
    var checkingpackage = this.state.selectedpackage;
    var selectedproduct = item;
    if (checkingselectedProductID.length < 3) {
      this.props.history.push({
        pathname: "/product-details/" + item.product_slug,

        state: {
          packageID: this.state.packageID,
          dayno: this.state.dayno,
          dayType: this.state.dayType,
          country: this.state.country,
          city: this.state.package_city,
          selectedProductID: checkingselectedProductID,
          selectedpackage: checkingpackage,
          selectedActivities: checkingselectedpackage,
          selectedproduct: selectedproduct,
          existActivity: this.state.existActivity,
          newPackage: this.state.newPackage,
        },
      });
    } else if (checkingselectedProductID.length >= 3) {
      showAlert("Error", "Maximum 3 activities only allow to add");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  removeSelectedHotel(index) {
    var checkingselectedHotelIDs = this.state.selectedProductID;
    var checkingselectedHotelList = this.state.selectedActivities;
    var selectedpackage = this.state.selectedpackage;
    checkingselectedHotelList.splice(index, 1);
    checkingselectedHotelIDs.splice(index, 1);
    selectedpackage.splice(index, 1);
    this.setState(
      {
        selectedActivities: checkingselectedHotelList,
        selectedProduselectedpackagectID: checkingselectedHotelIDs,
        selectedpackage: this.state.selectedpackage,
      },
      () => {
        this.displayProductList();
      }
    );
  }
  // continueActivities() {
  //   showLoader("submit_product", "class");
  //   var postObject = {
  //     product_id: JSON.stringify(this.state.selectedProductID),
  //     product_package_id: JSON.stringify(this.state.selectedpackage),
  //     package_id: this.state.packageID,
  //     dayno: this.state.dayno,
  //     creator_id: this.state.CUserId,

  //     newpackage: this.state.newpackage,
  //   };
  //   return false;
  //   axios
  //     .post(
  //       apiUrl + "package/selectproducts",
  //       qs.stringify(postObject),
  //       {
  //      headers: {
  //        Authorization: cookie.load("acccesstoken"),
  //      }
  //    }
  //     )
  //     .then((res) => {
  //       hideLoader("submit_product", "class");
  //       this.props.history.push({
  //         pathname: "/package-step/" + this.state.packageID,
  //         state: {
  //         },
  //       });
  //     });
  // }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");
    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  handleDocumentClick = (event) => {
    if (
      this.state.showsearchList &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ showsearchList: false });
    }
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            <div className="header-back-continue">
              <div className="color-header cheader-with-text">
                <h3>
                  Select unique<span> activities</span>
                </h3>
                <p>Itineraries of the activities</p>
              </div>
              <div className="hbc-rhs">
                <Link
                  to={{
                    pathname: "/package-step/" + this.state.packageID,
                    state: this.props.location.state,
                  }}
                  className="button ghost-button"
                >
                  Back
                </Link>
                {/* <a
                  href={void 0}
                  className="button submit_product"
                  onClick={this.continueActivities.bind(this)}
                >
                  Continue
                </a> */}
              </div>
            </div>
            <div className="sticky-options">
              <div className="sticky-options-lhs">
                {this.state.selectedActivities.length > 0 && (
                  <ul>
                    {this.state.selectedActivities.map((item, index) => {
                      return (
                        <li>
                          <div className="sop-img">
                            <img
                              src={
                                item.product_thumbnail !== "" &&
                                item.product_thumbnail !== null
                                  ? apiglobaltixMediaUrl +
                                    item.product_thumbnail
                                  : "https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/no+hotel+image+found+small.jpg"
                              }
                              alt={item.products_name}
                            />
                          </div>
                          <div className="sop-text">
                            <h5>
                              <a href={void 0}>
                                {stripslashes(item.products_name)}
                              </a>
                            </h5>
                          </div>
                          <div className="remove-sticky">
                            {" "}
                            <img
                              src={stickclose}
                              onClick={() => this.removeSelectedHotel(index)}
                            />{" "}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>

            <div className="flight-way-search choose-stay ">
              <div className="flight-search rel">
                <div className="choose-loc">
                  <input
                    type="text"
                    placeholder="Enter your activities"
                    value={this.state.search_keyword}
                    name="search_keyword"
                    onChange={this.handleChange.bind(this)}
                  />
                  {this.state.showsearchList && (
                    <div
                      ref={this.dropdownRef}
                      className={
                        this.state.showsearchList === true
                          ? "search-place active"
                          : "search-place"
                      }
                    >
                      <ul>
                        {/*               {this.state.displaySearchCountry} */}
                        {this.state.displaySearchProducts}
                      </ul>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="button fg-btn"
                  onClick={this.searchProduct.bind(this)}
                >
                  <img src={searchlight} />
                </button>
              </div>
            </div>

            <div className="outlet-listing-inner">
              <div className="outlet-listing-lhs">
                <h3>Filters</h3>
                {this.state.displaytag !== "" && (
                  <div className="filter-option cat-option">
                    <h5
                      className="accordion-header"
                      onClick={this.toggleAccordion}
                    >
                      <img src={ticketblue} alt="ticketblue" />
                      Category
                    </h5>
                    <ul>{this.state.displaytag}</ul>

                    <a
                      href={void 0}
                      onClick={this.resetFilter.bind(this, "tag")}
                      className="button ghost-button"
                    >
                      Reset
                    </a>
                  </div>
                )}
                <div className="filter-option pricerange-option">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={dollarblue} alt="dollarblue" />
                    Price range
                  </h5>
                  <p>
                    SGD ${Math.ceil(this.state.startPrice)} to SGD $
                    {Math.ceil(this.state.endPrice)}+
                  </p>
                  <div>
                    <input
                      type="text"
                      id="rang"
                      name="rang"
                      value=""
                      data-min="0"
                      data-max="1000"
                      data-from="0"
                      data-to="1000"
                      data-type="double"
                      data-prefix="$"
                      data-grid="false"
                      data-grid-num="10"
                    />
                  </div>
                </div>
                {/*  <div className="filter-option rating-option no-margin-btm">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={stario} alt="" />
                    Rating
                  </h5>
                  <ul>
                    <li
                      className={
                        this.state.selectedrating === 5 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 5)}
                      >
                        5 Excellent (28)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 4 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 4)}
                      >
                        4 Good (48)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 3 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 3)}
                      >
                        3 Okay (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 2 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 2)}
                      >
                        2 Poor (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 1 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 1)}
                      >
                        1 Terrible (79)
                      </a>
                    </li>
                  </ul>

                  <a
                    href={void 0}
                    onClick={this.resetFilter.bind(this, "rating")}
                    className="button ghost-button"
                  >
                    Reset
                  </a>
                </div> */}
                <div className="clearall">
                  <a href={void 0} onClick={this.clearAllFilters.bind(this)}>
                    Clear all
                  </a>
                </div>
              </div>

              <div className="outlet-listing-rhs">
                <div className="showing-header">
                  <h4>{this.props.totalRecord} things to do</h4>
                  <a href={void 0} onClick={this.openSortBy.bind(this)}>
                    Sort by <img src={barrow} alt="barrow" />
                  </a>

                  <ul
                    className={
                      this.state.enablesortby == true
                        ? "sort-by active"
                        : "sort-by"
                    }
                  >
                    <li
                      className={this.state.pricesort === "LH" ? "active" : ""}
                    >
                      <a
                        href={void 0}
                        onClick={this.applyPriceSort.bind(this, "LH")}
                      >
                        Price Low to High
                      </a>
                    </li>
                    <li
                      className={this.state.pricesort === "HL" ? "active" : ""}
                    >
                      <a
                        href={void 0}
                        onClick={this.applyPriceSort.bind(this, "HL")}
                      >
                        Price High to Low
                      </a>
                    </li>
                  </ul>
                </div>
                <ul>
                  {this.state.loading == true && (
                    <>
                      <li>
                        <ContentLoader
                          viewBox="0 0 500 280"
                          height={280}
                          width={500}
                          speed={3}
                        >
                          <rect
                            x="3"
                            y="3"
                            rx="10"
                            ry="10"
                            width="300"
                            height="180"
                          />
                          <rect
                            x="6"
                            y="190"
                            rx="0"
                            ry="0"
                            width="292"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="215"
                            rx="0"
                            ry="0"
                            width="239"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="242"
                            rx="0"
                            ry="0"
                            width="274"
                            height="20"
                          />
                        </ContentLoader>
                      </li>
                      <li>
                        <ContentLoader
                          viewBox="0 0 500 280"
                          height={280}
                          width={500}
                          speed={3}
                        >
                          <rect
                            x="3"
                            y="3"
                            rx="10"
                            ry="10"
                            width="300"
                            height="180"
                          />
                          <rect
                            x="6"
                            y="190"
                            rx="0"
                            ry="0"
                            width="292"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="215"
                            rx="0"
                            ry="0"
                            width="239"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="242"
                            rx="0"
                            ry="0"
                            width="274"
                            height="20"
                          />
                        </ContentLoader>
                      </li>
                      <li>
                        <ContentLoader
                          viewBox="0 0 500 280"
                          height={280}
                          width={500}
                          speed={3}
                        >
                          <rect
                            x="3"
                            y="3"
                            rx="10"
                            ry="10"
                            width="300"
                            height="180"
                          />
                          <rect
                            x="6"
                            y="190"
                            rx="0"
                            ry="0"
                            width="292"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="215"
                            rx="0"
                            ry="0"
                            width="239"
                            height="20"
                          />
                          <rect
                            x="4"
                            y="242"
                            rx="0"
                            ry="0"
                            width="274"
                            height="20"
                          />
                        </ContentLoader>
                      </li>
                    </>
                  )}
                  {this.state.loading == false && this.state.displayProducts}
                </ul>
                {this.state.pagenation !== "" && (
                  <div className="bottom-pagenation">
                    <ul className="pagenation">
                      <li className={this.state.page === 1 ? "disabled" : ""}>
                        <a href={void 0}>&#60;</a>
                      </li>
                      {this.state.pagenation}
                      <li
                        className={
                          this.state.disablelast === true ? "disabled" : ""
                        }
                      >
                        <a href={void 0}> &#62;</a>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="mobile-filter">
                  <div className="filter-group">
                    <a
                      href={void 0}
                      className={
                        this.state.pricesort === "HL"
                          ? "sorting active"
                          : "sorting"
                      }
                      onClick={this.applyPriceSortMobile.bind(
                        this,
                        this.state.pricesort
                      )}
                    >
                      <img src={sort} alt="sort" /> Sort
                    </a>
                    <a
                      href={void 0}
                      className="fitering"
                      onClick={this.showFilter.bind(this)}
                    >
                      <img src={filter} alt="filter" /> Filters
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var productlistArr = Array();
  var totalRecord = 0;
  var common = Array();
  var productListStatus = "";

  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
      totalRecord = state.productlist[0].total_records;
      common = state.productlist[0].common;
      productListStatus = "success";
    } else {
      productListStatus = "error";
    }
  }
  var segmentlistArr = Array();
  if (Object.keys(state.segmentlist).length > 0) {
    if (state.segmentlist[0].status === "ok") {
      segmentlistArr = state.segmentlist[0].result_set;
    }
  }

  var taglistArr = Array();
  if (Object.keys(state.taglist).length > 0) {
    if (state.taglist[0].status === "ok") {
      taglistArr = state.taglist[0].result_set;
    }
  }
  var countryArr = Array();
  if (Object.keys(state.country).length > 0) {
    if (state.country[0].status === "ok") {
      countryArr = state.country[0].result_set;
    }
  }
  return {
    productlist: productlistArr,
    productListStatus: productListStatus,
    totalRecord: totalRecord,
    common: common,
    segmentlist: segmentlistArr,
    taglist: taglistArr,
    favourite: state.favourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCTLIST, params });
    },
    getSegmentList: () => {
      dispatch({ type: GET_SEGMENTLIST });
    },
    getTagList: () => {
      dispatch({ type: GET_TAGLIST });
    },
    getFavouritelist: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Index);
