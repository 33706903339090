/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_CUSTOMER_REGISTRATION, SET_CUSTOMER_REGISTRATION } from "../actions";
import { userapiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCustomerRegistration = function* () {
  yield takeEvery(GET_CUSTOMER_REGISTRATION, workerGetCustomerRegistration);
};

function* workerGetCustomerRegistration({ formPayload }) {
  try {
    const result = yield call(getCustomerRegistration, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_REGISTRATION, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getCustomerRegistration(formPayload) {
  return Axios.post(
    userapiUrl + "customer/registration",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }
  );
}
