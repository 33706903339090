/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./Bookings.scss";
import searchicon from "../../../common/images/search.svg";
import fgrey from "../../../common/images/filter-grey.svg";
import { lighten, makeStyles } from "@mui/styles";
import {
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { UopUrl, apiUrl } from "../../Helpers/Config";
import cookie from "react-cookies";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    boxShadow: "0px 2px 10px #4258BF29 !important",
    borderRadius: "10px !important",
    padding: "10px !important",
    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function createData(
  name,
  productname,
  productid,
  bookingid,
  tourdate,
  status,
  action
) {
  return { name, productname, productid, bookingid, tourdate, status, action };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Lead traveller",
  },
  {
    id: "productname",
    numeric: true,
    disablePadding: false,
    label: "Product name",
  },
  {
    id: "productid",
    numeric: true,
    disablePadding: false,
    label: "Product Id",
  },
  {
    id: "bookingid",
    numeric: true,
    disablePadding: false,
    label: "Booking ID",
  },
  { id: "tourdate", numeric: true, disablePadding: false, label: "Tour date" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Bookings = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [data, setData] = React.useState([]);
  const [source, setSource] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [commissionDetails, setCommissionDetails] = useState(null);
  const [popupTitle, setPopupTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState("7days");
  const history = useHistory();

  let CUserId = "";
  if (
    cookie.load("CUserId") !== "" &&
    typeof cookie.load("CUserId") !== undefined &&
    typeof cookie.load("CUserId") !== "undefined"
  ) {
    CUserId = cookie.load("CUserId");
  } else {
    history.push("/");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Axios.get(
          `${UopUrl}/booking?id=${CUserId}&limit=${300}&offset=${1}&days=${selectedOption}`,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [selectedOption]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleViewDetail = (row) => {
    if (row?.creator_package_source === "vacation") {
      viewEaringdetils(row);
    } else {
      history.push(`/myaccount/booking-detail?id=${row?.booking_id}`);
    }
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleFilterClose = (value) => {
    setSource(value);
    setAnchorEl(null);
  };

  const handleFilterOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const viewEaringdetils = (creator) => {

   const fizo_header =  {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }

    Axios.get(apiUrl + "dashboard/commissiondetails", {
      params: {
        commission_creator_id: creator.booking_creator_id,
        commission_booking_id: creator.booking_id,
        days: "7days",
      },
      ...fizo_header,
    })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("response.data", response.data);
          setCommissionDetails(response.data);
          setPopupTitle(creator?.creator_package_name);
          $.magnificPopup.open({
            items: {
              src: "#earning-details",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="bookings-main">
      <div className="search-and-add">
        <div className="search-and-add-filter">
          <div className="search-and-add-form">
            <img src={searchicon} className="search-lft" />
            <input
              type="text"
              placeholder="Search products by Name, Id.."
              onChange={handleChange}
            />
          </div>
          <div className="search-and-filter">
            <a href="#" onClick={handleFilterOpen}>
              <img src={fgrey} />
              {source ? source?.label : "Filter"}
            </a>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={anchorEl !== null}
              onClose={handleCloseFilter}
              keepMounted
            >
              <MenuItem
                onClick={() =>
                  handleFilterClose({ label: "Normal", value: "vacation" })
                }
                sx={{ fontSize: "14px", fontFamily: "poppins" }}
              >
                Normal
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleFilterClose({ label: "UOP", value: "uop" })
                }
                sx={{ fontSize: "14px", fontFamily: "poppins" }}
              >
                UOP
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="three-merge-btn">
          <button
            className={`${selectedOption === "7days" ? "active" : ""}`}
            onClick={() => handleButtonClick("7days")}
          >
            Week
          </button>
          <button
            className={`${selectedOption === "30days" ? "active" : ""}`}
            onClick={() => handleButtonClick("30days")}
          >
            Month
          </button>
          <button
            className={`${selectedOption === "12months" ? "active" : ""}`}
            onClick={() => handleButtonClick("12months")}
          >
            Year
          </button>
        </div>
      </div>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(
                  searchQuery !== ""
                    ? data.filter(
                        (item) =>
                          item.creator_package_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          item.booking_customerName
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          String(item.creator_package_id).includes(searchQuery)
                      )
                    : source !== null
                    ? data.filter(
                        (item) => item?.creator_package_source === source?.value
                      )
                    : data,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                      >
                        {row.booking_customerName}
                      </TableCell>
                      <TableCell align="left">
                        {row.creator_package_name}
                      </TableCell>
                      <TableCell align="left">
                        {row.creator_package_id}
                      </TableCell>
                      <TableCell align="left">
                        {row.booking_reference_number}
                      </TableCell>
                      <TableCell align="left">
                        {row.tour_date}
                      </TableCell>
                      <TableCell align="left">
                        <div className="confirmed">
                          {row.booking_payment_status}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          className="viewBtn"
                          onClick={() => handleViewDetail(row)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {commissionDetails && (
          <div
            id="earning-details"
            className="mfp-hide common-popup earnpop-details"
          >
            <div className="ed-header">
              <h3>{popupTitle}</h3>
              <h4>Payout breakdown</h4>
            </div>
            <div className="ed-body">
              <ul>
                <li>
                  <span>Stay commission</span>
                  <strong>
                    S$ {commissionDetails.commission_details.stay}
                  </strong>
                </li>
                <li>
                  <span>Things to do commission</span>
                  <strong>
                    S$ {commissionDetails.commission_details.things_to_do}
                  </strong>
                </li>
                <li className="p-charge">
                  <span>Payout charges</span>
                  <strong>
                    S$
                    {commissionDetails.commission_details.payout_charges}
                  </strong>
                </li>
                <li>
                  <span>Total payout</span>
                  <strong>
                    $ {commissionDetails.commission_details.total_payout}
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookings;
