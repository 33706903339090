/* eslint-disable */
import React, { Component } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validated } from "react-custom-validation";
import cookie from "react-cookies";
import validator from "validator";
import axios from "axios";
import { apiUrl } from "../Helpers/Config";
const isEmpty = (value) =>
  value.trim() === "" ? "This field is required." : null;
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const minLength = (password, length) =>
  password.length >= length ? null : "Password must be at least 6 characters.";

const PasswordsPattern =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

const strongPassword = (password) =>
  PasswordsPattern.test(password) ? null : "Password isn't Strong Enough.";

const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";

const isHandleNameUnique = async (creator_handle_name) => {
  console.log(creator_handle_name, " [isHandleNameUnique]");
  var formData = new FormData();
  formData.append("creator_handle_name", creator_handle_name);
  try {
    const response = await axios.post(
      apiUrl + "creators/handlenameAvailability",
      formData,
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }
    );
    console.log(response, "responseee");
    if (response.data.status === "ok") {
      return true;
    } else {
      return false;
    }

    return null;
  } catch (error) {
    return "error checking";
  }
};

// const HandleNameUnique = async (creator_handle_name) => {
//   console.log(creator_handle_name, " [isHandleNameUnique]");
//   var formData = new FormData();
//   formData.append("creator_handle_name", creator_handle_name);
//   try {
//     const response = await axios.post(
//       apiUrl + "creators/handlenameAvailability",
//       formData
//     );
//     console.log(response, "responseee");

//     return response.data.message;
//   } catch (error) {
//     return "error checking";
//   }
// };

function validationConfigSignup(props) {
  const { customer_name, handle_name, email, password, rePassword, mobile } =
    props.fields;

  return {
    fields: [
      "customer_name",
      "handle_name",
      "email",
      "password",
      "rePassword",
      "mobile",
    ],

    validations: {
      customer_name: [[isEmpty, customer_name]],
      handle_name: [
        [isEmpty, handle_name],
        [isHandleNameUnique, handle_name],
      ],
      // handle_name: [
      //   [isEmpty, handle_name],
      //   [HandleNameUnique, handle_name],
      // ],
      email: [[isEmail, email]],
      password: [
        [minLength, password, 8],
        [strongPassword, password],
      ],
      rePassword: {
        rules: [[areSame, password, rePassword]],
        fields: ["password", "rePassword"],
      },
      mobile: [
        [isEmpty, mobile],
        /*  [isMobile, mobile], */
      ],
    },
  };
}

const phonenumberPatternph = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
  mobile.match(phonenumberPatternph)
    ? null
    : "Please enter valid Mobile number.";

class Creatorsignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: "password",
      cpasswordType: "password",
      handle_names: "",
    };
  }

  openPopup(popupID) {
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      midClick: true,
    });
  }
  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }

  showhideCPWD() {
    if (this.state.cpasswordType === "password") {
      this.setState({ cpasswordType: "text" });
    } else {
      this.setState({ cpasswordType: "password" });
    }
  }
  setPhone(phone, data) {
    this.props.onChange("mobile", phone);
    $("#countryCode").val(data.countryCode);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    const spanStyle = {
      clear: "both",
    };
    let errMsgCustomerName,
      errMsgCustomerHandleName,
      errMsgEmail,
      errMsgPassword,
      errMsgrePassword,
      errMsgMobile;

    if ($validation.customer_name.error.reason !== undefined) {
      errMsgCustomerName = $validation.customer_name.show && (
        <div className="error">{$validation.customer_name.error.reason}</div>
      );
    }
    if ($validation.handle_name.error.reason !== undefined) {
      errMsgCustomerHandleName = $validation.handle_name.show && (
        <div className="error">
          <p>Handlename is already taken</p>
        </div>
      );
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <div className="error">{$validation.email.error.reason}</div>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <div className="error">{$validation.password.error.reason}</div>
      );
    }
    if ($validation.rePassword.error.reason !== undefined) {
      errMsgrePassword = $validation.rePassword.show && (
        <div className="error">{$validation.rePassword.error.reason}</div>
      );
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <div className="error">{$validation.mobile.error.reason}</div>
      );
    }
    return (
      <div className="cp-form-inner">
        <div className="form-group">
          <label className="control-label">Name</label>
          <input
            type="text"
            placeholder="Enter your full name"
            value={fields.customer_name}
            {...$field("customer_name", (e) =>
              onChange("customer_name", e.target.value)
            )}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          />
          {errMsgCustomerName}
        </div>
        <div className="form-group">
          <label className="control-label">Handle Name</label>
          <input
            type="text"
            placeholder="Enter your handle name"
            value={fields.handle_name}
            {...$field("handle_name", (e) =>
              onChange("handle_name", e.target.value)
            )}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          />

          {$validation.handle_name.isValid === true && (
            <span className="ok-green">
              {" "}
              <CheckIcon style={{ color: "green" }} />
            </span>
          )}
          {errMsgCustomerHandleName}
        </div>
        <div className="form-group">
          <label className="control-label">Email</label>
          <input
            type="text"
            placeholder="Enter your Email"
            value={fields.email}
            {...$field("email", (e) => onChange("email", e.target.value))}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          />
          {errMsgEmail}
        </div>
        <div className="form-group">
          <label className="control-label">Phone</label>
          <input type="hidden" id="countryCode" />
          <PhoneInput
            value={fields.mobile}
            country={"sg"}
            onChange={(phone, data, event, formattedValue) => {
              this.setPhone(formattedValue, data);
            }}
          />
          {errMsgMobile}
        </div>
        <div className="form-group pwd-group">
          <label className="control-label">Password</label>
          <div className="pwd-group">
            <input
              type={this.state.passwordType}
              placeholder="Min 8 characters"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            />
            {errMsgPassword}
            <span
              className={this.state.passwordType === "text" ? "active" : ""}
            >
              <i
                className="fa fa-eye-slash"
                aria-hidden="true"
                onClick={this.showhidePWD.bind(this)}
              ></i>
              <i
                className="fa fa-eye"
                aria-hidden="true"
                onClick={this.showhidePWD.bind(this)}
              ></i>
            </span>
          </div>
        </div>
        <div className="form-group pwd-group no-margin-btm">
          <label className="control-label">Confirm Password</label>
          <div className="pwd-group">
            <input
              type={this.state.cpasswordType}
              placeholder="Min 8 characters"
              value={fields.rePassword}
              {...$field("rePin", (e) =>
                onChange("rePassword", e.target.value)
              )}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            />
            {errMsgrePassword}
            <span
              className={this.state.cpasswordType === "text" ? "active" : ""}
            >
              <i
                className="fa fa-eye-slash"
                aria-hidden="true"
                onClick={this.showhideCPWD.bind(this)}
              ></i>
              <i
                className="fa fa-eye"
                aria-hidden="true"
                onClick={this.showhideCPWD.bind(this)}
              ></i>
            </span>
          </div>
        </div>
        <div className="help-text">
          Already have account?{" "}
          <a href={void 0} onClick={this.openPopup.bind(this, "#login-popup")}>
            Login
          </a>
        </div>
        <div className="form-button">
          <button
            type="submit"
            className="button signup_submit"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Create account
          </button>
        </div>
      </div>
    );
  }
}
Creatorsignup = validated(validationConfigSignup)(Creatorsignup);

export default withRouter(Creatorsignup);
