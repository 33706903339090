/* eslint-disable */
import React, { useEffect } from "react";
import "./FilterSheet.scss";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilterSheet = (props) => {
  const {
    state,
    currentThis,
    handleApplyFilter,
    handleClearAll,
    handleChange,
    closeFilterSheet,
    categorylist,
    handleDaysFilter,
    daysFilter,
    nextYearMonths,
    search_days,
    segmentFilter,
    priceFilterOptions,
    priceFilter,
    handlePriceFilter
  } = props;

  useEffect(() => {
    $("#rang-mobile").ionRangeSlider({
      min: state.startPrice,
      max: state.maxPrice,
      from: state.startPrice,
      to: state.endPrice,
      type: "double",
      grid_num: 10,
      onFinish: function (data) {
        currentThis.setState({ packageLoading: true });
        currentThis.setState(
          { startPrice: data.from, endPrice: data.to },
          function () {
            currentThis.loadFilter();
            currentThis.displayPackage();
          }
        );
        setTimeout(() => {
          currentThis.setState({ packageLoading: false });
        }, 2000);
      },
    });
  }, [state]);

  return (
    <div className="filterSheetMain">
      <div className="cp-header textcenter">
        <h3>Filters</h3><CloseIcon onClick={() => closeFilterSheet()}/>
      </div>
      <div className="filter-row">
        <h5>Days</h5>
        {daysFilter?.map((item, index) => (
          <ul className="filter-row-ul" key={index}>
            <li key={index}>
              <input
                type="checkbox"
                className="tag_filter"
                checked={search_days?.includes(item.value)}
                id={"day_" + index}
                value={item.value}
                onChange={(ev) => handleDaysFilter(item.value)}
              />
              <label htmlFor={"day_" + index}>{item.label}</label>
            </li>
          </ul>
        ))}
      </div>
      {state?.packageType === "uop" && (
        <>
          <br />
          <div className="filter-row">
            <div className="head-row">
              <h5>Months </h5>{" "}
            </div>
            <ul className="filter-row-ul">
              {nextYearMonths?.map((item, index) => {
                return (!state.showMoreMonths && index < 5) ||
                  state.showMoreMonths ? (
                  <li key={index}>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      checked={state.selectedMonth === item?.value}
                      id={"day_" + index}
                      value={item.value}
                      onChange={() => currentThis.monthFilter(item?.value)}
                    />
                    <label htmlFor={"day_" + index}>{item.label}</label>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
          <span
            onClick={() =>
              currentThis.setState({
                showMoreMonths: !state.showMoreMonths,
              })
            }
            className="showMoreBtn"
          >
            {state.showMoreMonths ? "Show less" : "Show more"}
          </span>
          <br />
        </>
      )}
      <div className="filter-body slider">
        <div className="filter-row pricerange-option">
          <div className="head-row">
            <h5>Price </h5>{" "}
          </div>
          <ul className="filter-row-ul">
              {priceFilterOptions?.map((item, index) => {
                return (
                  <li key={index}>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      checked={priceFilter === item?.value}
                      id={"price_" + index}
                      value={item.value}
                      onChange={() => handlePriceFilter(item?.value)}
                    />
                    <label htmlFor={"price_" + index}>{item.label}</label>
                  </li>
                );
              
              })}
          </ul>
        </div>
      </div>
      <div className="filter-row">
        <h5>categories</h5>
        {state.category.map((item, index) => {
          return (
            <ul className="filter-row-ul" key={index}>
              <li key={index}>
                <input
                  type="checkbox"
                  className="tag_filter"
                  checked={categorylist?.includes(item)}
                  id={"category" + index}
                  value={item}
                  onChange={(ev) => handleChange("category", ev)}
                />
                <label htmlFor={"category_" + index}>{item}</label>
              </li>
            </ul>
          );
        })}
      </div>
      <div className="filter-action">
        <a
          href="#"
          className="button"
          onClick={() => {
            handleApplyFilter();
            closeFilterSheet();
          }}
        >
          Apply
        </a>
        <a
          href="#"
          className="button ghost-button"
          onClick={() => {
            handleClearAll();
            closeFilterSheet();
          }}
        >
          Clear All
        </a>
      </div>
    </div>
  );
};

export default React.memo(FilterSheet);
