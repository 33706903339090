/* eslint-disable */
import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
import "./common/css/font-awesome.css";
import "./common/css/style.css";
import "./common/css/responsive-style.css";
import "./common/css/airline.css";
import "./common/css/owl.carousel.css";
import Creatorstay from "./components/Package/Creatorstay";
import Countries from "./components/Home/Countries";
import Customerattractions from "./components/Customerattractions/Index";
import Index from "./components/Attractions/Index";
import Productdetail from "./components/Attractions/Productdetail";
import Profile from "./components/Myaccount/Profile";
import Profilecreator from "./components/Myaccount/ProfileCreator/Profilecreator";
import PayoutDetails from "./components/Myaccount/PayoutDetails";
import CreatorProfile from "./components/Myaccount/CreatorProfile";
import PayoutList from "./components/Myaccount/PayoutList";
import Earnings from "./components/Myaccount/Earnings";
import PublicProfile from "./components/Myaccount/PublicProfile";
import Wishlist from "./components/Myaccount/Wishlist";
import Mybooking from "./components/Myaccount/Mybooking";
import Cancel from "./components/Myaccount/Cancel";
import Thankyou from "./components/Checkout/Thankyou";
import Account from "./components/Account/Account";
import CreatorAccount from "./components/Account/CreatorAccount";
import Promo from "./components/Promotions/Promo";
import FunThings from "./components/Pages/FunThings";
import Logout from "./components/Myaccount/Logout";
import Cancelrequest from "./components/Pages/Cancelrequest";
import Dashboard from "./components/Creator/Dashboard";
import CreatorMyproducts from "./components/Creator/CreatorMyproducts";
import AddProducts from "./components/Creator/AddProducts";
import GeneralInfo from "./components/Creator/GeneralInfo/GeneralInfo";
import AddPhotos from "./components/Creator/AddPhotos/AddPhotos";
/* import CreatorItinerary from "./components/Creator/CreatorItinerary"; */
import ItineraryStay from "./components/Creator/ItineraryStay";
import ItineraryList from "./components/Creator/ItineraryList";
// import ItineraryTransfer from "./components/Creator/Pricing";
import ItineraryActivity from "./components/Creator/ItineraryActivity/ItineraryActivity";

// import Landing17 from "./components/Myaccount/Landing17";
// import Landing20 from "./components/Myaccount/Landing20";
import Packagestep from "./components/Package/Packagestep";
import Creatordetailsedit from "./components/Myaccount/Creatordetailsedit";
import AdminLogin from "./admin/AdminLogin";
import PackageDashboard from "./admin/PackageDashboard";
/* For Vecation Market Place */
import Package from "./components/Package/Package";
import Packagedetails from "./components/Package/Packagedetails";
import BookingScreen from "./components/Myaccount/BookingScreen";
import Staydetail from "./components/Package/Staydetail";
/* Customer */
import Customerpackage from "./components/Customerpackage/Customerpackage";
import Activitiesdetail from "./components/Customerpackage/Activitiesdetail";
import Customerstaydetail from "./components/Customerpackage/Customerstaydetail";
import Userflightdetail from "./components/Customerpackage/Userflightdetail";
import UsertranferSelection from "./components/Customerpackage/UsertranferSelection";
import Staylist from "./components/Customerpackage/Staylist";
import Creatoranding from "./components/Creator/Creatoranding";
import Customerpackageactivities from "./components/Customerpackage/Customerpackageactivities";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import Termsandconditions from "./components/Policies/Termsandconditions";
import Cancellation from "./components/Policies/Cancellation";
import Page404 from "./Page404";
import CityPackages from "./components/Home/CityPackages";
/* About Us */
import Aboutus from "./components/Pages/Aboutus";
import Helpcenter from "./components/Pages/Helpcenter";
import Helpcentertopics from "./components/Pages/Helpcentertopics";
import Helpcenterdetail from "./components/Pages/Helpcenterdetail";
import Refpage from "./components/Layout/Refpage";
import CreatorList from "./components/Creator/CreatorList";
import VacationSummary from "./components/VacationSummary/VacationSummary";
const Customerpackagedetail = lazy(() =>
  import("./components/Customerpackage/Customerpackagedetail")
);
const Checkout = lazy(() => import("./components/Checkout/Index"));
const Process = lazy(() => import("./components/Checkout/Process"));
const Customerflightselection = lazy(() =>
  import("./components/Customerpackage/Customerflightselection")
);
const Customerattractiondetails = lazy(() =>
  import("./components/Customerattractions/Productdetail")
);
import Home from "./components/Home/Home";
const Fallback = () => (
  <div id="dvLoading">
    <div className="loader triangle">
      <svg viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="black"
          strokeWidth="3"
          fill="transparent"
        />
      </svg>
    </div>
  </div>
);

import MyAccountLayout from "./components/Creator/MyAccountLayout";
import Availability from "./components/Creator/Availability/Availability";
import Pricing from "./components/Creator/Pricing/Pricing";
import Bookings from "./components/Creator/Bookings/Bookings";
import BookingDetail from "./components/Creator/BookingDetail/BookingDetail";
import PromotionalPackage from "./components/Customerpackage/PromotionalPackage";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        {/* Customer Section Start */}
        <Route exact path="/" component={Home} />
        <Suspense fallback={<Fallback />}>
          <Route
            exact
            path="/countries_profile/:countryName"
            component={Countries}
          />
          <Route
            path="/search-package/:packageSlug/flight"
            component={Customerflightselection}
          />
          <Route
            path="/search-package/:packageSlug/new-flight"
            component={Customerflightselection}
          />

          <Route
            path="/search-package/:packageSlug/flight-details"
            component={Userflightdetail}
          />
          <Route
            path="/search-package/:packageSlug/transfer"
            component={UsertranferSelection}
          />

          <Route
            exact
            path="/search-package/:packageSlug/all-activities/:country"
            component={Customerattractions}
          />
          <Route
            exact
            path="/search-package/:packageSlug/all-activities/:country/:productslug"
            component={Customerattractiondetails}
          />

          <Route
            exact
            path="/search-package/:packageSlug/activities/:activitiesSlug"
            component={Activitiesdetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/stay/:country/:city"
            component={Staylist}
          />
          <Route
            exact
            path="/search-package/:packageSlug/stay/:hotelID"
            component={Customerstaydetail}
          />

          <Route
            exact
            path="/search-package/:packageSlug"
            component={Customerpackagedetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/:packageType/share"
            component={Customerpackagedetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/creatorshare"
            component={Customerpackagedetail}
          />
          <Route exact path="/summary" component={VacationSummary} />
          <Route exact path="/search-package" component={Customerpackage} />
          <Route
            exact
            path="/packages/:countryName/:cityName"
            component={Customerpackage}
          />
          <Route exact path="/packages/:cityName" component={Customerpackage} />
          <Route
            exact
            path="/:packageSlug/package-activities"
            component={Customerpackageactivities}
          />

          {/* Customer Section End */}

          {/* Creator Section Start */}
          {/* <Route exact path="/create-package" component={Packagedetails} /> */}
          <Route
            exact
            path="/edit-package/:packageID"
            component={Packagedetails}
          />
          <Route
            exact
            path="/package-step/:packageID"
            component={Packagestep}
          />

          <Route
            path="/activities/:country/:city/:packageID/:day/:dayType"
            component={Index}
          />
          <Route
            path="/creator-stay/:country/:city/:packageID/:hotelID"
            component={Staydetail}
          />
          <Route
            path="/creator-stay/:country/:city/:packageID/"
            component={Creatorstay}
          />
          <Route exact path="/booking" component={Checkout} />
          {/* Creator Section End */}

          {/*  Extra */}
          <Route exact path="/activities/:country" component={Index} />
          <Route exact path="/activities/" component={Index} />
          <Route
            exact
            path="/product-details/:productslug"
            component={Productdetail}
          />

          <Route exact path="/process_order" component={Process} />
          <Route exact path="/thankyou/:bookingID" component={Thankyou} />
          {/* <Route exact path="/help" component={Help} /> */}

          <Route exact path="/profile" component={Profile} />
          <Route exact path="/payoutlist" component={PayoutList} />
          <Route exact path="/public_profile" component={PublicProfile} />
          <Route exact path="/creator_profile" component={CreatorProfile} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/mybooking" component={Mybooking} />
          <Route exact path="/mybooking/:bookingID" component={Mybooking} />
          <Route exact path="/cancellation" component={Cancel} />
          <Route
            path="/account/activation/:activationKey"
            component={Account}
          />
          <Route path="/account/resetpassword/:resetKey" component={Home} />
          <Route
            path="/creatoraccount/activation/:activationKey"
            component={CreatorAccount}
          />
          <Route
            path="/creatoraccount/resetpassword/:resetKey"
            component={Home}
          />
          <Route path="/promotions/:promoslug" component={Promo} />
          <Route path="/fun-things/:funthingslug" component={FunThings} />
          <Route path="/cancell-request/:requestID" component={Cancelrequest} />
          <Route path="/cancell-request" component={Cancelrequest} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/helpcenter" component={Helpcenter} />
          <Route path="/helpcenter-topics" component={Helpcentertopics} />
          <Route path="/helpcenter-detail" component={Helpcenterdetail} />
          <Route path="/admin" component={AdminLogin} />
          <Route path="/packagedashboard" component={PackageDashboard} />

          {/* <Route path={"/"}>
            <MyAccountLayout></MyAccountLayout>
          </Route> */}
          <Route path={"/myaccount"} component={MyAccountLayout}>
            {/* <MyAccountLayout>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/bookings" component={Bookings} />
              <Route path="/booking-detail" component={BookingDetail} />
              <Route exact path="/commission" component={Earnings} />
              <Route exact path="/profilecreator" component={Profilecreator} />
              <Route
                path="/creatordetails-edit"
                component={Creatordetailsedit}
              />
              <Route exact path="/payoutdetails" component={PayoutDetails} />
              <Route path="/creator-myproducts" component={CreatorMyproducts} />
              <Route path="/add-myproducts" component={AddProducts} />
              <Route path="/helpcenter" component={Helpcenter} />
              <Route path="/package" component={Package} />
              <Route path="/general-info" component={GeneralInfo} />
              <Route path="/add-photos" component={AddPhotos} />
              <Route path="/itinerary-list" component={ItineraryList} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/availability" component={Availability} />
              <Route path="/itinerary-activity" component={ItineraryActivity} />
            </MyAccountLayout> */}
          </Route>

          {/*   <Route path="/citinerary-list" component={CreatorItinerary} /> */}
          <Route path="/itinerary-stay" component={ItineraryStay} />
          {/* <Route path="/itinerary-list" component={ItineraryList} /> */}
          {/* <Route path="/itinerary-activity" component={ItineraryActivity} /> */}

          {/* <Route path="/landing17" component={Landing17} /> */}

          {/* <Route path="/landing20" component={Landing20} /> */}
          <Route path="/bookingscreen" component={BookingScreen} />

          {/* <Route path="/userstayselect" component={UserstaySelection} /> */}
          {/* <Route path="/userthingsselect" component={UserthingsSelection} /> */}
          <Route path="/creator" component={Creatoranding} />
          <Route exact path="/logout" component={Logout} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={Termsandconditions} />
          <Route path="/cancellation-and-refund" component={Cancellation} />
          <Route path="/refpage/:slugtext" component={Refpage} />
          <Route path="/refpage/" component={Refpage} />
          <Route path="/404" component={Page404} />
        </Suspense>
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
